// https://router.vuejs.kr/guide/

import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
// @ts-ignore
import auto_routes from '~pages'
import {setEnvFunction} from "@/lib/env/useLogin.ts";
import {routeAdmin} from "@/router/menu_admin.ts";
import {routeMyPages} from "@/router/menu_mypage.ts";


const children1: RouteRecordRaw[] = [
  // { path: '/main', name: 'main.wt',             component: () => import('@/pages/Index.vue') },
  // { path: '/',          redirect: '/login' },
  // { path: '/',          redirect: '/wiki/board' },
  { path: '/',          redirect: '/w' },
  // { path: '/',
    // redirect: async to => {
    //   const loginStatus = await apiGetLoginStatus()
    //   return loginStatus.login
    //     ? '/wiki/board'
    //     : '/login'
    // },
    
    // beforeEnter: (to, from, next) => {
    //   apiGetLoginStatus('routes.ts').then(ret => {
    //     if (ret.login) {
    //       next('/w'); // 로그인 상태면 대시보드로 리디렉션
    //     } else {
    //       next('/login'); // 로그인 상태가 아니면 로그인 페이지로 리디렉션
    //     }
    //   });
    // },

  // 테스트
  // { path: '/test/t_tree',                    component: () => import('@/pages/test/t_tree.vue') },
  // { path: '/test/t_attach-files',            component: () => import('@/pages/test/t-attach-files2.vue') },


  { path: '/board',       component: () => import('@/pages/board/board.vue') },
  { path: '/w',           component: () => import('@/pages/wiki/wiki_view.vue') },
  { path: '/w/:id',       component: () => import('@/pages/wiki/wiki_view.vue'), props: true },
  { path: '/wiki/form',   component: () => import('@/pages/wiki/wiki_form.vue') },
];

// const log = T.getLogger("ROUTE", "info")

// 모든 요청에 env 를 주입한다. - 즉, 페이지 이동시마다 env 를 조회한다. 덤으로 세션 정보도 업데이트 된다.
children1.forEach(item => !item.beforeEnter && (item.beforeEnter = setEnvFunction))


const routes: RouteRecordRaw[] = [
  // {path: '/', component: () => import('@/pages/Main.vue')}, // https://router.vuejs.kr/guide/advanced/lazy-loading.html
  // {path: '/sub1', component: () => import('@/pages/Sub1.vue')}, // https://router.vuejs.kr/guide/advanced/lazy-loading.html
  // {path: '/test', component: () => import('@/pages_test/index.vue')}, // https://router.vuejs.kr/guide/advanced/lazy-loading.html

  { path: '/login',                component: () => import('@/pages/login/login.vue') },
  {
    path: '/',
    // component: () => import('layouts/MainLayout.vue'),
    component: () => import('@/layouts/WikiLayout.vue'),
    children: children1, // [canweed] menu 로 이동시킴. 메뉴에서도 같이 쓰려고
    // children: [{ path: '', component: () => import('@/pages/Index.vue') }]
  },
  
  
  ...auto_routes,
  routeAdmin,
  routeMyPages,

  { path: '/test/totp',   component: () => import('@/components/TOTP.vue') },
  { path: '/test/passkey',   component: () => import('@/components/PASSKEY.vue') },
  
  // 404처리 https://router.vuejs.kr/guide/essentials/history-mode.html#caveat
  {path: '/:pathMatch(.*)', component: () => import('@/pages/ErrorNotFound.vue')},
]

// console.log("routes = ", routes)

const router = createRouter({
  // history: createMemoryHistory(), // https://router.vuejs.kr/guide/essentials/history-mode.html#memory-mode
  history: createWebHistory(), // https://router.vuejs.kr/guide/essentials/history-mode.html#html5-mode
  routes: routes,
})

export { routes }

export default router
