import {treeTextParse} from "@/lib/lib_treeTextParse";
import {RouteRecordRaw} from "vue-router";
import {setEnvFunction} from "@/lib/env/useLogin.ts";
// 자동변환 : mgr*/display/display -> pages/display/display

// link 에 해당하는 route 는 반드시 routes.ts 에 정의되어야 한다.
const menuSource = `
name                            | link
내 정보 관리                    | /mypage/my_profile
`

const routesAdmin: RouteRecordRaw[] = [
  {path: '/mypage/my_profile'    , component: () => import('@/pages/mypage/my_profile.vue')},
]

// 모든 요청에 env 를 주입한다. - 즉, 페이지 이동시마다 env 를 조회한다. 덤으로 세션 정보도 업데이트 된다.
routesAdmin.forEach(item => !item.beforeEnter && (item.beforeEnter = setEnvFunction))


export const { treeList: menuTreeSrc, itemList: menuItems } =
  treeTextParse({
    menuSource: menuSource})

export const routeMyPages: RouteRecordRaw = {
  path: '/mypage', component: () => import('@/layouts/MyPageLayout.vue'),
  children: routesAdmin
}

