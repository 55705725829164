// noinspection JSUnusedGlobalSymbols

import {App} from "vue";
import {EnvTypeExt} from "@/lib/env/type-env";
import {SessionState, useSessionStore} from "@/lib/env/useSessionStore.ts";



// -- 그냥 $env 로 사용
// fe/front/src/_init/config_global.ts 에서 정의하고
//
//     Object.defineProperty(app.config.globalProperties, '$env', {
//       get: function () {
//         return this.$route.meta.env;
//       }
//     })

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $env: EnvTypeExt
    $session: SessionState // pinia 방식
  }
}

export default {
  install(app: App) {

    // 이렇게 하면 {{ $env().user }} 이런식으로 써야함..
    // app.config.globalProperties.$env = function() {
    //   return this.$route.meta.env;
    // }

    // 이렇게 하면 그냥 {{ $env.user }} 로 사용이 가능하다.
    // 이미 설정한 값을 이용하므로 성능면에서는 제일 좋다
    Object.defineProperty(app.config.globalProperties, '$env', {
      get: function () {
        return this.$route.meta.env
        // return useSessionStore().env
      }
    })

    // pinia 방식 - 이렇게 하면 그냥 {{ $session }} 로 사용이 가능하다.
    // 그런대 매번 store 를 생성하기 떄문에 비추
    Object.defineProperty(app.config.globalProperties, '$session', {
      get: () => useSessionStore()
    })
    
    
    
    // -- 개념상 제일 좋은건 그냥 필요한 .vue 에서 const {env} = useSessionStore() 를 사용하는 것이다.

  },
}

// -- 템플릿에서 사용
// {{ $env.user }}

// -- 코드에서 사용
// const inst = getCurrentInstance()
// log.debug("inst?.proxy?.$env.user?.id             = " + inst?.proxy?.$env?.user?.id) // 이건.. 비추
// log.debug("inst?.proxy?.$env?.hasRole('master')   = " + inst?.proxy?.$env?.hasRole('master')) 

