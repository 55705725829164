/**
 * T의 아주 기본 함수들
 * null, undefined, NaN 체크(is) 또는 대체(if)
 */
import T from './index'

export default {

  /**
   * null 또는 undefined인가 ?
   */
  isNU: (obj: any): boolean => obj === null || obj === undefined,

  /**
   * null 또는 undefined 인게 하나라도 있나 ?
   * @param {...*} args
   * @returns {boolean}
   */
  isNUAny: (...args) => args.some(v => T.isNU(v)),

  /**
   * not null 이면서 not undefined 인가 ?
   * @param {*} obj
   * @returns {boolean}
   */
  isNotNU: obj => obj !== null && obj !== undefined,

  /**
   * 모든 변수들이 isNotNU인가 ? <pre>
   *   T.isNotNUAll("aa","bb") === true
   *   T.isNotNUAll("aa", undefined) === false
   * </pre>
   * @param {...any} obj_list 문자열들
   */
  isNotNUAll: (...obj_list) => obj_list.every(T.isNotNU),

  /**
   * 변수들중 하나라도 isNotNU인가 ? <pre>
   *   T.isNotNUAny(null, null) === false
   *   T.isNotNUAny("aa", undefined) === true
   * </pre>
   * @param {...any} obj_list 문자열들
   */
  isNotNUAny: (...obj_list) => obj_list.some(T.isNotNU),

  /**
   * 눈에 보이는 기준으로 not empty 인지 확인<pre>
   *   T.isNotEmpty(null)      === false
   *   T.isNotEmpty(undefined) === false
   *   T.isNotEmpty("")        === false
   *   T.isNotEmpty(" ")       === true
   *   T.isNotEmpty(0)         === true
   *   T.isNotEmpty(1)         === true
   *   T.isNotEmpty(true)      === true  // boolean 타입은 무조건 not empty
   *   T.isNotEmpty(false)     === true  // boolean 타입은 무조건 not empty
   *   T.isNotEmpty({})        === false
   *   T.isNotEmpty([])        === false
   *   기타 알수없는 타입      === false
   * </pre>
   * @param {string} str
   * @returns {boolean}
   */
  isNotEmpty: str => {
    if (T.isNU(str)) return false  // null 이거나 undefined 라면 무조건 false
    if (typeof str === 'string') return str.length > 0 // 문장이면 길이가 0보다 클때
    if (typeof str === 'number') return str != 0 // 숫자인 경우 0이 아닐때
    if (typeof str === 'boolean') return true // boolean 타입은 무조건 true
    if (Array.isArray(str)) return str.length > 0 // 배열이면 길이가 0보다 클때
    if (_.isPlainObject(str)) return !_.isEmpty(str) // 맵 형태인 경우 데이터가 있나 확인
    return false // 기타 알수없는 타입인경우 무조건 false
    // T.isNU(str) ? false : typeof str === 'string' ? str.length > 0 : true
  },

  /**
   * 눈에 보이는 기준으로 empty 확인<pre>
   *   T.isEmpty(null)      === true
   *   T.isEmpty(undefined) === true
   *   T.isEmpty("")        === true
   *   T.isEmpty(" ")       === false
   *   T.isEmpty(0)         === false
   *   T.isEmpty(1)         === false
   *   T.isEmpty({})        === true
   *   T.isEmpty([])        === true
   *   기타 알수없는 타입   === true
   * </pre>
   * @param {string} str
   * @returns {boolean}
   */
  isEmpty: str => !T.isNotEmpty(str),

  /**
   * 값들 중 empty인게 하나라도 있나<pre>
   * </pre>
   * @param {string[]} str
   * @returns {boolean}
   */
  isEmptyAny: (...str) => str.some(s => T.isEmpty(s)),


  /**
   * 만약 NaN( Not-A-Number: 숫자가 아님) 일때 defaultVal 를 반환한다.<pre>
   *   T.ifNaN(123)    === 123
   *   T.ifNaN(NaN)    === 0
   *   T.ifNaN(NaN, 1) === 1
   * </pre>
   * @param obj 평가 대상
   * @param defaultNum NaN인경우 대체할 값 ( 기본값 0 )
   */
  ifNaN: (obj: any, defaultNum: number | null = 0): number => isNaN(obj) ? defaultNum : obj,

  /**
   * value 가 null 이거나 undefined 이면 defaultVal 를 반환한다.<pre>
   *   T.ifNU(7)              == 7
   *   T.ifNU(null)           == ''
   *   T.ifNU(undefined)      == ''
   *   T.ifNU(null, 1)        == 1
   *   T.ifNU(undefined, 123) === 123
   * </pre>
   * @param {*} val
   * @param {*} defaultVal (기본:'') value가 null이거나 undefined 일때 대체할 값.
   * @returns {*} value가 null이거나 undefined 일때 defaultVal 아니면 value
   */
  ifNU: (val, defaultVal:any = '') => T.isNU(val) ? defaultVal : val,

  /**
   * 해당 객체에 해당 key가 존재하는지 확인한다. ( hasOwnProperty 를 사용한다 )
   * @param {*} obj 객체
   * @param {string} key 키 이름
   */
  hasKey: (obj, key) => Object.prototype.hasOwnProperty.call(obj, key),

}
